/* VARIABLES */

:root {
  --P950: #2f1e5a;
  --P900: rgba(54, 52, 85, 1);
  --P800: rgba(72, 65, 108, 1);
  --P700: rgba(74, 69, 130, 1);
  --P600: rgba(79, 40, 179, 1);
  --P500: rgba(120, 84, 247, 1);
  --P400: rgba(153, 122, 246, 1);
  --P300: rgba(164, 140, 232, 1);
  --P200: rgba(201, 187, 239, 1);
  --P100: rgba(226, 216, 255, 1);
  --B900: rgba(10, 14, 46, 1);
  --B800: rgba(35, 39, 67, 1);
  --B700: rgba(59, 62, 88, 1);
  --B600: rgba(84, 87, 109, 1);
  --B500: rgba(108, 110, 130, 1);
  --B400: rgba(133, 135, 151, 1);
  --B300: rgba(157, 159, 171, 1);
  --B200: rgba(184, 186, 198, 1);
  --B100: rgba(213, 215, 224, 1);
  --WT100: rgba(255, 255, 255, 1);
  --WT80: rgba(255, 255, 255, 0.8);
  --WT60: rgba(255, 255, 255, 0.6);
  --WT30: rgba(255, 255, 255, 0.3);
  --WT15: rgba(255, 255, 255, 0.15);
  --WT10: rgba(255, 255, 255, 0.1);
  --BT100: rgba(0, 0, 0, 1);
  --BT80: rgba(0, 0, 0, 0.8);
  --BT60: rgba(0, 0, 0, 0.6);
  --BT30: rgba(0, 0, 0, 0.3);
  --BT15: rgba(0, 0, 0, 0.15);
  --BT10: rgba(0, 0, 0, 0.1);
  --G900: #17132c;
  --G800: #2a2540;
  --G700: #3e3a57;
  --G600: #565272;
  --G500: #767192;
  --G400: #938faa;
  --G300: #b4b2c5;
  --G200: #c9c8d7;
  --G100: #dbdbe5;
  --G50: #eaebf2;
  --G25: #f9fbff;
  --NEW-P900: #1e104e;
  --NEW-P800: #311e70;
  --NEW-P700: #432c94;
  --NEW-P600: #5b3fc0;
  --NEW-P500: #7854f7;
  --NEW-P400: #957afa;
  --NEW-P300: #b29ffc;
  --NEW-P200: #c6bbfd;
  --NEW-P100: #d7d0fe;
  --NEW-P50: #e5e3ff;
  --NEW-P25: #f3f5ff;
  --GR-PG: linear-gradient(270deg, #2ec693 0%, #7854f7 100%);
  --GR-G: linear-gradient(270deg, #157a6e 0%, #1eeda7 100%);
  --GR-PP: linear-gradient(90deg, #7e75ec 0%, #b961ec 100%);
  --GR-NEW-PG: linear-gradient(270deg, #1eeda7 0%, #997af6 100%);
  --GR-NEW-PP: linear-gradient(270deg, #eeadff 0%, #bea8ff 100%);
  --GR-NEW-BY: linear-gradient(90deg, #ffdd68 0%, #1eeda7 100%);
  --GR-NEW-G: linear-gradient(
    90deg,
    rgba(46, 198, 147, 1) 0%,
    rgba(21, 122, 110, 1) 100%
  );
  --GR-NEW-G2: linear-gradient(90deg, #1eeda7 0%, #a2fedf 100%);

  --Y: rgba(248, 176, 92, 1);
  --R: rgba(223, 41, 53, 1);
  --G: rgba(21, 122, 110, 1);
  --SG: rgba(46, 198, 147, 1);
  --LG: rgba(30, 237, 167, 1);
  --SLG: #a2fedf;
  --PINK: #b961ec;

  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;
  --font-black: 900;
  --font: 'Poppins', sans-serif;
}

@font-face {
  font-family: 'Poppins';
  src: url('./font/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./font/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./font/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./font/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: var(--font) !important;
}

html,
body {
  font-family: var(--font) !important;
  font-size: 62.5% !important;
  /* background:  linear-gradient(180deg, #0a0e2e 14%, #322460 100%); */
  color: var(--WT100);
  min-height: 100vh;
}

div,
a,
input,
button {
  font-size: 1.4rem;
}

a,
button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: var(--WT60);
}

img {
  pointer-events: none;
}

.clip {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  background: var(--WT100);
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
}

.rounded-full {
  border-radius: 50%;
}

body {
  min-width: 960px;
  margin: auto;
  letter-spacing: -0.01rem;
}
.Toastify__toast {
  background-color: var(--P800);
  border-radius: 32px;
  box-shadow: 0px 4px 16px var(--BT30);
  padding: 28px;
  width: inherit;
  margin: 0;
}
.Toastify__toast-container {
  width: auto;
}
.Toastify__toast-body {
  font-family: var(--font);
  color: #fff;
  padding: 0;
}
.Toastify__close-button,
.Toastify__toast-icon {
  display: none;
}
.Toastify__toast-container--bottom-center {
  bottom: 30px;
  right: 30px;
  left: unset;
  transform: none;
}

.emoji {
  font-family: 'Segoe UI Emoji';
  line-height: 1rem;
  font-weight: 400;
}

/* NEW FONT */

.h1-black {
  font-size: 7.2rem;
  line-height: 9.4rem;
  font-weight: var(--font-black);
}

.h2-bold {
  font-size: 6rem;
  line-height: 7.8rem;
  font-weight: var(--font-bold);
}

.h3-bold {
  font-size: 4.8rem;
  line-height: 6.4rem;
  font-weight: var(--font-bold);
}

.h4-bold {
  font-size: 3.6rem;
  line-height: 4.8rem;
  font-weight: var(--font-bold);
}

.h5-bold {
  font-size: 3rem;
  line-height: 4.2rem;
  font-weight: var(--font-bold);
}

.h5-medium {
  font-size: 3rem;
  line-height: 4.2rem;
  font-weight: var(--font-medium);
}

.h5-regular {
  font-size: 3rem;
  line-height: 4.2rem;
  font-weight: var(--font-regular);
}

.h6-bold {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-weight: var(--font-bold);
}

.h6-medium {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-weight: var(--font-medium);
}

.h6-regular {
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-weight: var(--font-regular);
}

.h7-bold {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: var(--font-bold);
}

.h7-medium {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: var(--font-medium);
}

.h7-regular {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: var(--font-regular);
}

.b1-bold {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: var(--font-bold);
}

.b1-medium {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: var(--font-medium);
}

.b1-regular {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: var(--font-regular);
}

.b2-bold {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: var(--font-bold);
}

.b2-medium {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: var(--font-medium);
}

.b2-regular {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: var(--font-regular);
}

.cap1-bold {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: var(--font-bold);
}

.cap1-medium {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: var(--font-medium);
}

.cap1-regular {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: var(--font-regular);
}

.cap1-light {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 300;
}

.cap2-light,
.cap2-regular {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: var(--font-regular);
}

.cap2-medium {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: var(--font-medium);
}

.cap2-bold {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: var(--font-bold);
}

.P900 {
  color: var(--P900);
}
.P800 {
  color: var(--P800);
}
.P700 {
  color: var(--P700);
}
.P600 {
  color: var(--P600);
}
.P500 {
  color: var(--P500);
}
.P400 {
  color: var(--P400);
}
.P300 {
  color: var(--P300);
}
.P200 {
  color: var(--P200);
}
.P100 {
  color: var(--P100);
}
.B900 {
  color: var(--B900);
}
.B800 {
  color: var(--B800);
}
.B700 {
  color: var(--B700);
}
.B600 {
  color: var(--B600);
}
.B500 {
  color: var(--B500);
}
.B400 {
  color: var(--B400);
}
.B300 {
  color: var(--B300);
}
.B200 {
  color: var(--B200);
}
.B100 {
  color: var(--B100);
}
.WT100 {
  color: var(--WT100);
}
.WT80 {
  color: var(--WT80);
}
.WT60 {
  color: var(--WT60);
}
.WT30 {
  color: var(--WT30);
}
.WT15 {
  color: var(--WT15);
}
.BT100 {
  color: var(--BT100);
}
.BT60 {
  color: var(--BT60);
}
.BT30 {
  color: var(--BT30);
}
.BT15 {
  color: var(--BT15);
}
.G900 {
  color: var(--G900);
}
.G800 {
  color: var(--G800);
}
.G700 {
  color: var(--G700);
}
.G600 {
  color: var(--G600);
}
.G500 {
  color: var(--G500);
}
.G400 {
  color: var(--G400);
}
.G300 {
  color: var(--G300);
}
.G200 {
  color: var(--G200);
}
.G100 {
  color: var(--G100);
}
.G50 {
  color: var(--G50);
}
.G25 {
  color: var(--G25);
}
.LG {
  color: var(--LG);
}
.SLG {
  color: var(--SLG);
}

.yellow {
  color: var(--Y);
}
.red {
  color: var(--R);
}
.green {
  color: var(--G);
}

.scroll::-webkit-scrollbar:horizontal {
  display: none;
}

.scroll::-webkit-scrollbar {
  width: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: var(--WT30);
  border-radius: 10px;
}
.scroll::-webkit-scrollbar-track {
  background: none;
}
.scroll::-webkit-scrollbar-corner {
  background-color: var(--BT0);
}
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* paragraph style */
.pre-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.line-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* web3modal fix */
.web3modal-modal-lightbox {
  z-index: 100000 !important;
}

.swiper-button-prev {
  width: 36px !important;
  background: url('/image/left-arrow.png') left center no-repeat;
  background-size: 36px 36px;
}
.swiper-button-next {
  width: 36px !important;
  background: url('/image/right-arrow.png') right center no-repeat;
  background-size: 36px 36px;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

/* coinbase fix */
.-cbwsdk-css-reset .-cbwsdk-connect-content {
  width: 740px !important;
}
.-cbwsdk-css-reset .-cbwsdk-try-extension {
  width: 740px !important;
}
